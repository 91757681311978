<template lang="pug">
div.view_container
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.loading(v-if="$apollo.loading")
      img(v-bind:src="loadingSrc")
  div(v-if="!$apollo.loading && !done && !contactWithSlug")
    div.view_title
      span This link does not work!
    div.controls
      button.cta_secondary(v-on:click="next") What is this about?
  div(v-if="!$apollo.loading && !done && contactWithSlug")
    div.view_title
      span When is your birthday {{addressToName}}?
    div.description
      span.bold {{senderName}}
      span &nbsp; is using WishWell to help celebrate important dates for people they care for.
    div.set_birthdate
      DatePicker(v-model='birthdate' color="indigo" is-dark)
    div.controls
      button.cta(v-on:click="updateBirthdate" ref="submit" style="touch-action: manipulation") {{ buttonTitle }}
  div(v-if="done")
    div.view_title Thanks {{addressToName}}! 😀
    div.description WishWell can help you and your friends create a group video on each other's birthday too.
    div.controls
      button.cta_secondary(v-on:click="next") Find out about birthday reminders

</template>

<script>
/* global gtag */
import { UPDATE_CONTACT_BIRTHDATE_WITH_SLUG_MUTATION } from '../graphql/mutations'
import { GET_CONTACT_WITH_SLUG_QUERY } from '../graphql/queries'

import Header from '../components/Header'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'UpdateContactBirthdate',
  props: {
    slug: String,
    project_slug: String,
  },
  components: {
    Header,
    DatePicker,
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    buttonTitle () {
      return this.loading ? "Updating..." : "Submit"
    },
    addressToName () {
      return this.contactWithSlug?.firstName
    },
    senderName () {
      return this.contactWithSlug?.participator.name
    }
  },
  data () {
    return {
      isSmallScreen: false,
      birthdate: null,
      loading: false,
      contactWithSlug:null,
      done: false
    }
  },
  apollo: {
    contactWithSlug () {
      return {
        // gql query
        query: GET_CONTACT_WITH_SLUG_QUERY,
        variables () {
          return {
            slug: this.slug,
          }
        },
        update: data => {
          this.birthdate = data.contactWithSlug.birthdate
          return data.contactWithSlug
        },
        error: (error) => {
          console.log(error.message)
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    next: function() {
      if (this.project_slug) {
        this.$router.push('/reminders/'+this.project_slug)
      } else {
        this.$router.push('/reminders/')
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    updateBirthdate: async function() {
      if (!this.birthdate) {
        this.$toast.error('Please select your birthdate')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_CONTACT_BIRTHDATE_WITH_SLUG_MUTATION,
        variables: {
          input: {
            slug: this.slug,
            birthdate: this.birthdate,
          }
        },
      }).then((data) => {
        this.loading = false
        const success = data.data.updateContactBirthdate.success
        const errors = data.data.updateContactBirthdate.errors
        // console.log(data)
        if (success) {
          this.$toast.success('Thanks for sharing your birthday!',{
            timeout: 1000,
            onClose: () => this.done = true
          })
        }
        this.errorsToast(errors)
      }).catch((error) => {
        this.loading = false
        // console.error(error)
        this.errorToast(error)
      })
    },
  },
  mounted: function() {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/update_contact_birthdate'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.controls {
  margin-top: 18px;
}

.set_birthdate {
  margin-top: 18px;
}

.view_title {
  margin-bottom: 8px;
}

.bold {
  font-family: gibsonsemibold;
}

.description {
  font-size: 20px;
}

.cta_secondary {
  display: block;
  margin: 0 14px 14px 0;
}
</style>
